const paths = [
  {
    path: '/about',
    name: '회사소개',
    subMenu: [
      { path: '/about', name: '개요' },
      { path: '/about/group', name: '조직' },
    ],
  },
  {
    path: '/product',
    name: '서비스',
    subMenu: [
      { path: '/product', name: '세일즈로그' },
      { path: '/product/learninglog', name: '러닝로그' },
    ],
  },
  {
    path: '/board',
    name: '새소식',
    subMenu: [
      { path: '/board', name: '공지사항' },
      // { path: '/board/data', name: '자료실' },
      { path: 'https://blog.naver.com/saleslog', name: '블로그' },
    ],
  },
  {
    path: '/contact',
    name: '문의',
    subMenu: [{ path: '/contact', name: '문의 바로가기' }],
  },
  {
    path: '/recruit',
    name: '파트너모집',
    subMenu: [
      // { path: '/recruit', name: '채용개요' },
      { path: '/recruit/partner', name: '파트너모집' },
    ],
  },
];

export default paths;
