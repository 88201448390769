import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ScrollButton from '../ScrollButton';
var classNames = require('classnames');

const faceBookLink = 'https://facebook.com/theklab';
const blogLink = 'https://blog.naver.com/saleslog';
export default class Footer extends Component {
  render() {
    return (
      <footer>
        <div className="lnk">
          <div className="container">
            <Link to="/termofuse">이용약관</Link>
            &nbsp;&nbsp; | &nbsp;&nbsp;
            <Link to="/privacy">개인정보처리방침</Link>
            &nbsp;&nbsp; | &nbsp;&nbsp;
            <Link to="contact">제휴 및 문의</Link>
          </div>
        </div>
        <div className="container">
          <ul className={classNames('row', 'con')}>
            <li className={classNames('col-xs-12', 'col-md-2')}>
              <img
                className="f_logo"
                src={require('../../../assets/img/common/foot_logo.png')}
                alt=""
              />
            </li>
            <li className={classNames('col-xs-12', 'col-md-8')}>
              주식회사 더클랩 <br />
              서울특별시 서초구 반포대로 30길 81 웅진타워 12F<br />
              <span className="copy">
                CopyRight 2020 THEKLAB CORP.ALRIGHT RESERVED
              </span>
            </li>
            <li className={classNames('col-xs-12', 'col-md-2')}>
              <a href={faceBookLink}>
                <img
                  src={require('../../../assets/img/common/icon_face.png')}
                  alt=""
                />
              </a>
              <a href={blogLink}>
                <img
                  src={require('../../../assets/img/common/icon_blog.png')}
                  alt=""
                />
              </a>
            </li>
          </ul>
        </div>
      </footer>
    );
  }
}
