import React from 'react';
import { Route } from 'react-router-dom';

const Main = React.lazy(() => import('./pages'));
const About = React.lazy(() => import('./pages/about'));
const Board = React.lazy(() => import('./pages/board'));
const Contact = React.lazy(() => import('./pages/contact'));
const Product = React.lazy(() => import('./pages/product'));
const Recruit = React.lazy(() => import('./pages/recruit'));
const Privacy = React.lazy(() => import('./pages/privacy'));
const TermOfUse = React.lazy(() => import('./pages/termofuse'));
/*
  configuration of route
*/
const routes = [
  { path: '/', name: 'Main', component: Main, route: Route, exact: true },
  {
    path: '/about',
    name: 'About',
    component: About,
    route: Route,
    exact: false,
  },
  {
    path: '/product',
    name: 'Product',
    component: Product,
    route: Route,
    exact: false,
  },
  {
    path: '/board',
    name: 'Board',
    component: Board,
    route: Route,
    exact: false,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    route: Route,
    exact: false,
  },
  {
    path: '/recruit',
    name: 'Recruit',
    component: Recruit,
    route: Route,
    exact: false,
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
    route: Route,
    exact: false,
  },
  {
    path: '/termofuse',
    name: 'TermOfUse',
    component: TermOfUse,
    route: Route,
    exact: false
  }
];

export { routes };
