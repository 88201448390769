import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import paths from './paths';
import '../../../assets/css/menu.css';

import MobNavMenu from '../MobNavMenu';
var classNames = require('classnames');

export default function Header() {
  const [movNavOpen, setMovNav] = useState(true);

  const toggleMovMenu = () => {
    setMovNav(!movNavOpen);
    const _className = movNavOpen ? 'open' : null;
    document.getElementsByTagName('html')[0].className = _className;
    document.getElementById('menu').className = _className;
    document.getElementsByClassName('page_cover')[0].className = movNavOpen
      ? classNames('page_cover', 'open')
      : 'page_cover';
  };

  const toggleSubMenu = (index) => {
    var className = document.getElementById('open_menu' + index).className;
    document.getElementById('open_menu' + index).className =
      className === 'open_menu' ? classNames('open_menu', 'open') : 'open_menu';
  };
  return (
    <header>
      <div className={classNames('container', 'web')}>
        <div className={classNames('row')}>
          <div className={classNames('top_logo', 'col-xs-2')}>
            <Link to="/">
              <img
                src={require('../../../assets/img/common/top_logo.png')}
                alt="logo"
              />
            </Link>
          </div>
          {/* top_logo */}
          <nav className={'col-xs-8'}>
            <ul className="menu">
              {paths.map(({ name, path, subMenu }, key) => {
                return (
                  <li key={key}>
                    <Link className="h5" to={path}>
                      <span>{name}</span>
                    </Link>
                    <ul className="sub_menu">
                      {subMenu.map(({ name, path }, key) => {
                        return (
                          <li key={key}>
                            {isExternal(path) ? (
                              <a
                                href={path}
                                target="_blank"
                                rel="noreferrer noopener">
                                {name}
                              </a>
                            ) : (
                              <Link to={path}>{name}</Link>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              })}
            </ul>
            <div className="nav_on"></div>
          </nav>
          <div className={classNames('col-xs-2', 'lan')}>
            <select name="" id="">
              <option value="">KOR</option>
            </select>
          </div>
        </div>
        {/* row */}
      </div>
      {/* container */}

      <div className="mob">
        <div className="mob_nav">
          <div className="mob_back"></div>
          <div className="mob_logo">
            <a href="/">
              <img
                src={require('../../../assets/img/common/mob_logo.png')}
                alt=""
              />
            </a>
          </div>
          <div className="mob_btn" onClick={toggleMovMenu}></div>
        </div>
        {/* mov_nav */}
      </div>
      <div id="menu">
        <div className="close" onClick={toggleMovMenu}>
          <i className={classNames('icon', 'xi-close')}></i>
        </div>
        <div className="menu_log">
          <img
            src={require('../../../assets/img/common/mob_logo.png')}
            alt=""
          />
        </div>

        <ul className="nav" id="nav-left">
          {paths.map(({ name, path, subMenu }, index, key) => {
            return (
              <li
                className={'open_menu'}
                id={'open_menu' + index}
                onClick={() => toggleSubMenu(index)}>
                <MobNavMenu name={name} subMenu={subMenu} />
              </li>
            );
          })}
        </ul>
        {/* nav-left */}
      </div>
      <div className="page_cover"></div>
    </header>
  );
}

function isExternal(url) {
  if (url.includes('https://')) return true;
  else return false;
}
