import React, { useState } from "react";
import "react-slidedown/lib/slidedown.css";
import { SlideDown } from "react-slidedown";
import { Link } from "react-router-dom";
export default function MovNavMenu(props) {
  const [open, setOpen] = useState(false);
  const toggleMenu = () => {
    setOpen(!open);
  };
  return (
    <React.Fragment>
      <div id={"nav_left_menu"} onClick={toggleMenu}>
        {props.name}
      </div>
      <SlideDown>
        {open ? (
          <ul className="nav_left_sub_menu">
            {props.subMenu.map(({ path, name }) => {
              return (
                <li>
                  <Link to={path}>{name}</Link>
                </li>
              );
            })}
          </ul>
        ) : null}
      </SlideDown>
    </React.Fragment>
  );
}
