import React, { Component, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { routes } from './routes';

import Header from './components/structure/Header';
import Footer from './components/structure/Footer';
import ScrollButton from './components/structure/ScrollButton';
// for loading component
const loading = () => <div>loading...</div>;

// exports the component with layout wrapped to it
const withLayout = (WrappedComponent) => {
  const HOC = class extends Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Header />

        {routes.map((route, index) => {
          return (
            <route.route
              key={index}
              path={route.path}
              exact={route.exact}
              component={withLayout((props) => {
                return (
                  <Suspense fallback={loading()}>
                    <route.component {...props} />
                  </Suspense>
                );
              })}
            />
          );
        })}
        <Footer />
        <ScrollButton scrollStepInPx="50" delayInMs="16.66" />
      </BrowserRouter>
    );
  }
}

export default App;
