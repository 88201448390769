import React, { Component } from 'react';
var classNames = require('classnames');
export class ScrollButton extends Component {
  constructor() {
    super();

    this.state = {
      intervalId: 0,
    };
  }

  scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
  }

  scrollToTop() {
    let intervalId = setInterval(
      this.scrollStep.bind(this),
      this.props.delayInMs
    );
    this.setState({ intervalId: intervalId });
  }

  render() {
    return (
      <span
        className={classNames('top', 'main_bg')}
        onClick={() => this.scrollToTop()}>
        <div>
          <i className={classNames('icon', 'xi-caret-up')} />
          <br />
          TOP
        </div>
      </span>
    );
  }
}

export default ScrollButton;
